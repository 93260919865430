console.log("👋")

// **********
// hero overlay header neg margin
// **********
if ($('.overlay-hero')[0]) {
	var headerHeight = $('.header').outerHeight();

	$('.overlay-hero').css({
		'margin-top': '-' + headerHeight + 'px'
	});

	$('.overlay-hero .container').css({
		'padding-top': headerHeight + 'px'
	});

	$('.header').addClass('header--overlay')
}

// **********
// nav toggle
// **********
if ($(window).width() < 1000) {
	$(".js-nav-toggle").click(function() {
		$(this).toggleClass("nav-toggle--active");
		$(".nav").toggleClass("nav--active");
	});
}


	
